<template>
  <v-row justify="center" class="my-0 py-4">
    <v-col cols="11" class="white rounded-xl">
      <v-tabs :color="color.primaryVariant" v-model="tab">
        <v-tab>
          <span class="wc-caption-1">MEMBRESÍAS</span>
        </v-tab>
        <v-tab>
          <span class="wc-caption-1">CUOTAS</span>
        </v-tab>
        <v-tab>
          <span class="wc-caption-1">INSCRIPCIONES</span>
        </v-tab>
        <v-tab>
          <span class="wc-caption-1">EVENTOS</span>
        </v-tab>
        <v-tab>
          <span class="wc-caption-1">CAPACITACIÓN</span>
        </v-tab>
        <v-tab>
          <span class="wc-caption-1">LISTAS</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Memberships></Memberships>
        </v-tab-item>
        <v-tab-item>
          <Fees></Fees>
        </v-tab-item>
        <v-tab-item>
          <Inscriptions></Inscriptions>
        </v-tab-item>
        <v-tab-item>
          <Events></Events>
        </v-tab-item>
        <v-tab-item>
          <Capacitacion></Capacitacion>
        </v-tab-item>
        <v-tab-item>
          <Regiones></Regiones>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import Chat from "@/components/shared/Chat.vue";

import { colors } from "@/constants/colors";
import Fees from "./fees/index.vue";
import Events from "./events/index.vue";
import Memberships from "./membership/index.vue";
import Inscriptions from "./inscriptions/index.vue";
import Capacitacion from "./training";
import Regiones from "./regions";
import { mapState } from "vuex";
export default {
  name: "Graphs",
  components: {
    Chat,
    Fees,
    Capacitacion,
    Regiones,
    Events,
    Memberships,
    Inscriptions,
  },
  data() {
    return {
      color: colors,
      tab: null,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
};
</script>

<style></style>
