<template>
  <v-row justify="space-around">
    <v-col cols="12">
      <v-card class="rounded-xl" elevation="0" color="#073165" rounded>
        <v-row class="ma-0 py-4 text-center h-100" justify="center">
          <span class="text-center">
            <span class="wc-overline texto-gris"> Boletos vendidos </span>

            <br />
            <span class="wc-subtitle-1 text-center texto-azul-claro">
              {{
                this.graphData.membersmenTickets +
                this.graphData.memberswomenTickets +
                this.graphData.submembersmenTickets +
                this.graphData.submemberswomenTickets +
                this.graphData.publicTickets
              }}
            </span>
          </span>
        </v-row>
      </v-card>
    </v-col>

    <v-col cols="12">
      <BarChart :chartData="barChartData"></BarChart>
    </v-col>

    <v-col cols="12">
      <v-card class="rounded-xl" elevation="0" color="#073165" rounded>
        <v-row class="ma-0 py-4 text-center h-100" justify="center">
          <span class="text-center">
            <span class="wc-overline texto-gris"> Ingresos </span>

            <br />
            <span class="wc-subtitle-1 text-center texto-azul-claro">
              {{ formatFees(this.graphData.totalRevenue) }}
            </span>
          </span>
        </v-row>
      </v-card>
    </v-col>
    <!--  -->
    <v-col cols="12" md="5">
      <PieChart :chartData="pieChart1Data"></PieChart>
    </v-col>
    <v-col cols="12" md="5">
      <PieChart :chartData="pieChart2Data"></PieChart>
    </v-col>
  </v-row>
</template>

<script>
import PieChart from "../fees/PieChart.vue";
import BarChart from "./BarChart.vue";
export default {
  name: "FeesGraphs",
  components: {
    PieChart,
    BarChart,
  },
  props: {
    graphData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatFees(value) {
      let formatedNumber = new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "MXN",
      }).format(value);

      formatedNumber = formatedNumber.split(",")[0];

      return "$" + formatedNumber;
    },
  },
  computed: {
    barChartData() {
      return {
        labels: ["Hombres", "Mujeres", "Publico en general"],
        datasets: [
          {
            label: "Asociados",
            data: [
              this.graphData.membersmenTickets,
              this.graphData.memberswomenTickets,
              0,
            ],
            backgroundColor: "rgba(40,67,135,0.9)",
          },
          {
            label: "Afiliados",
            data: [
              this.graphData.submembersmenTickets,
              this.graphData.submemberswomenTickets,
              0,
            ],
            backgroundColor: "rgba(227, 61, 148, 0.9)",
          },
          {
            label: "Público General",
            data: [0, 0, this.graphData.publicTickets],
            backgroundColor: "rgba(37, 40, 80, 0.9)",
          },
        ],
      };
    },
    pieChart1Data() {
      return {
        labels: ["Hombres", "Mujeres", "Publico en general"],
        datasets: [
          {
            label: "Distribución",
            data: [
              this.graphData.menRevenue,
              this.graphData.womenRevenue,
              this.graphData.publicRevenue,
            ],
            backgroundColor: ["rgba(40,67,135,0.9)", "rgba(227, 61, 148, 0.9)", "rgba(255, 223, 40, 0.9)"],
          },
        ],
      };
    },
    pieChart2Data() {
      return {
        labels: ["Asociados", "Afiliados", "Publico en general"],
        datasets: [
          {
            label: "Distribución",
            data: [
              this.graphData.membersRevenue,
              this.graphData.submembersRevenue,
              this.graphData.publicRevenue,
            ],
            backgroundColor: ["rgba(40,67,135,0.9)", "rgba(37, 40, 80, 0.5)", "rgba(37, 40, 80, 0.9)"],
          },
        ],
      };
    },
  },
};
</script>

<style>
.test {
  background-color: rgba(153, 63, 110, 0.9);
}
</style>