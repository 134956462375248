<template>
  <v-row justify="center" class="my-0 py-4">
    <v-col cols="12" class="white rounded-xl">
      <v-col>
        <v-row>
          <v-col cols="12" sm="2" class="text-left">
            <p class="wc-h3 text-primary-variant">Listas</p>
          </v-col>
          <v-col cols="12" sm="8" class="text-right">
            <v-btn color="orange" @click="exportToExcel">Exportar</v-btn>
          </v-col>
        </v-row>

        <br />

        <v-row>
          <v-col class="text-left" cols="12" sm="5">
            <v-select
              solo
              dense
              v-model="selectedOption"
              :items="options"
              label="Seleccione una opción"
              @change="updateDisplayData"
            ></v-select>
          </v-col>

          <v-col
            class="text-right"
            cols="12"
            sm="5"
            v-if="selectedOption === 'local' || selectedOption === 'regional'"
          >
            <v-select
              v-if="selectedOption === 'regional'"
              chips
              solo
              dense
              multiple
              clearable
              item-value="value"
              item-text="text"
              return-object
              v-model="selectedRegionalOptions"
              :items="secondOptions"
              label="Seleccione secciones locales"
              @change="updateDisplayData"
            ></v-select>
            <v-select
              v-else
              solo
              dense
              v-model="selectedSecondOption"
              :items="secondOptions"
              item-value="value"
              item-text="text"
              return-object
              label="Seleccione una sección local"
              @change="updateDisplayData"
            ></v-select>
          </v-col>
        </v-row>

        <br />
        <v-row>
          <v-col cols="12" sm="6">
            <v-menu :close-on-content-click="false" v-model="filtersMenu">
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  bordered
                  color="#1b273e"
                  overlap
                  :content="modifiedFilterFields.length || ''"
                  :value="modifiedFilterFields.length > 0"
                >
                  <v-btn color="#1b273e" dark v-bind="attrs" v-on="on">
                    <v-icon>mdi-filter-variant</v-icon>
                    Búsqueda

                    <template v-if="modifiedFilterFields.length">
                      <v-btn icon @click.stop="resetFilters()">
                        <v-icon color="white">mdi-close-circle-outline</v-icon>
                      </v-btn>
                    </template>
                  </v-btn>
                </v-badge>
              </template>

              <v-card elevation="4" class="filters-card">
                <v-card-title>
                  <v-row justify="space-between" class="ma-2">
                    <span class="wc-subtitle-1">Buscar por</span>

                    <v-btn
                      color="#1b273e"
                      class="white--text"
                      @click="applyFilters()"
                    >
                      <span class="wc-caption">Aplicar filtros</span>
                    </v-btn>
                  </v-row>
                </v-card-title>

                <v-row class="ma-1" justify="center">
                  <v-col cols="10">
                    <div class="searchbar d-flex flex-row my-4 align-center">
                      <v-btn
                        fab
                        small
                        elevation="0"
                        class="rounded-pill rounded-r-0 search-prepend"
                        color="#1b273e"
                      >
                        <v-icon size="20" color="white">mdi-magnify</v-icon>
                      </v-btn>
                      <v-text-field
                        solo
                        dense
                        hide-details="auto"
                        type="text"
                        placeholder="Buscar filtro"
                        class="busqueda rounded-pill rounded-l-0"
                        v-model="filterSearch"
                      />
                    </div>
                  </v-col>
                </v-row>

                <v-row class="mx-4">
                  <v-expansion-panels class="expansion-panel-width">
                    <v-expansion-panel
                      v-for="(field, index) in filterFieldsComputed"
                      :key="index"
                    >
                      <v-expansion-panel-header>
                        {{ field.name }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row class="ma-0">
                          <v-col cols="12" class="py-1">
                            <!-- Campos de entrada para filtros -->
                            <v-text-field
                              v-if="field.fieldType == 'TEXT'"
                              :label="field.name"
                              v-model="field.value"
                              filled
                              dense
                              hide-details="auto"
                              @change="
                                modifiedFilterFields.push(field.formattedName)
                              "
                            />
                            <v-select
                              v-if="field.fieldType === 'LIST'"
                              v-model="field.value"
                              :items="field.options"
                              :label="field.name"
                              class="higher-z-index"
                              @change="
                                modifiedFilterFields.push(field.formattedName)
                              "
                            />
                            <v-text-field
                              v-if="field.fieldType == 'NUMBER'"
                              v-model="field.value"
                              :label="field.name"
                              filled
                              dense
                              type="number"
                              @change="
                                modifiedFilterFields.push(field.formattedName)
                              "
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <br /><br />
        <p class="wc-h6 text-primary-variant" v-if="displayFilters">
          {{ displayFilters }}
        </p>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-left">Sección</th>
              <th class="text-left">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="selectedOption === 'nacional'">
              <td>Nacional</td>
              <td>{{ this.membersPaginated.totalDocs }}</td>
            </tr>
            <tr v-else-if="selectedOption === 'local'">
              <td>
                {{ selectedSecondOption ? selectedSecondOption.text : "" }}
              </td>
              <td>{{ this.membersPaginated.totalDocs }}</td>
            </tr>
            <tr
              v-for="region in regionalDocsCounts"
              :key="region.name"
              v-else-if="selectedOption === 'regional'"
            >
              <td>{{ region.name }}</td>
              <td>{{ region.totalDocs }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-col>
  </v-row>
</template>


<script>
import * as XLSX from "xlsx";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      startDate: null,
      endDate: null,
      regionalDocsCounts: [],

      memberFields: [],
      filtersMenu: false,
      filterSearch: "",
      modifiedFilterFields: [],
      filterFields: [],
      eventsSearchData: {
        name: "",
        branch: "",
        role: "",
        page: 1,
        perPage: 10,
        validationStatus: "",
      },

      selectedOption: "nacional",
      selectedSecondOption: null,
      selectedRegionalOptions: [],
      options: [
        { text: "Nacional", value: "nacional" },
        { text: "Local", value: "local" },
        { text: "Regional", value: "regional" },
      ],
      secondOptions: [],
      displayType: "",
      displayNames: [],
    };
  },
  computed: {
    ...mapState("members", ["members", "membersPaginated"]),
    ...mapState("loader", ["loaderVisible"]),
    ...mapState("user", ["user"]),
    displayFilters() {
      const filters = [];
      if (this.modifiedFilterFields.length) {
        this.modifiedFilterFields.forEach((f) => {
          const field = this.filterFields.find(
            (field) => field.formattedName === f
          );
          if (field) {
            filters.push(`${field.name}: ${field.value}`);
          }
        });
      }
      return filters.join(",  ");
    },
    filterFieldsComputed() {
      if (!this.filterSearch) {
        return this.filterFields;
      } else {
        return this.filterFields.filter((field) => {
          let name = field.name.toLowerCase();
          let search = this.filterSearch.toLowerCase();
          return name.includes(search);
        });
      }
    },
  },
  methods: {
    ...mapActions("branches", ["getBranches", "fetchChapterByName"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("members", ["clearmembersPaginated"]),
    ...mapActions("members", [
      "getMembers",
      "createMember",
      "getMemberIndicators",
      "getChapterIndicators",
      "getBranchMembersPaginated",
      "updateMember",
      "deleteMember",
    ]),
    ...mapActions("branches", [
      "getBranches",
      "createBranchMember",
      "updateBranchMember",
    ]),

    ...mapActions("registrationFields", ["getRegistrationFields"]),
    async fetchBranches() {
      // this.loading();
      try {
        const response = await this.getBranches();

        if (response.status === 200 && response.branches) {
          //console.log("entra2")
          //console.log("riposta", response.branches)
          this.secondOptions = response.branches.map((branch) => ({
            text: branch.company,
            value: branch._id,
          }));
          console.log("api", this.secondOptions);
        }
        // this.loaded();
      } catch (error) {
        console.log("no entra");
        console.error("Error fetching branches:", error);
        //this.loaded();
      }
    },
    formatMemberData(member) {
      let docs = [];
      let fields = [];

      if (!member.memberData) {
        member.memberData = [];
      }

      // if(!member.fiscalData){
      //   member.fiscalData = {
      //     legalName: '',
      //     taxId: '',
      //     street: '',
      //     zipCode: '',
      //     email: '',
      //     rf: ''
      //   }
      // }

      if (this.memberFields) {
        for (let i = 0; i < this.memberFields.length; i++) {
          const fieldToPush = JSON.parse(JSON.stringify(this.memberFields[i]));
          fieldToPush.value = "";
          if (this.memberFields[i].fieldType == "DOC") {
            docs.push(fieldToPush);
          } else {
            fields.push(fieldToPush);
          }
          delete this.memberFields[i].organization;
        }
      }

      for (let i = 0; i < fields.length; i++) {
        for (let j = 0; j < member.memberData.length; j++) {
          if (fields[i].formattedName == member.memberData[j].formattedName) {
            fields[i].value = member.memberData[j].value;
          }
        }
      }

      for (let i = 0; i < docs.length; i++) {
        for (let j = 0; j < member.memberData.length; j++) {
          if (docs[i].formattedName == member.memberData[j].formattedName) {
            docs[i].value = member.memberData[j].value;
          }
        }
      }
      member.deleteDialog = false;
      member.confirmDelete = false;
      member.authDelete = false;
      member.docs = JSON.parse(JSON.stringify(docs));
      member.fields = JSON.parse(JSON.stringify(fields));
      /*
      if (member.hasOwnProperty('membership')) {
      let tempID = member.membership._id;

      let response =  this.getAsociateInfoById(tempID);

      
        this.AsociateInfo = Object.assign(this.AsociateInfo, response.data);
        console.log(this.AsociateInfo);
      
    }*/
      return member;
    },

    applyFilters() {
      this.filtersMenu = false;
      let formattedFilters = [];
      this.modifiedFilterFields.forEach((formattedName) => {
        let filter = this.filterFields.find(
          (field) => field.formattedName == formattedName
        );
        formattedFilters.push(filter);
      });
      this.eventsSearchData.filters = formattedFilters;
      this.filter();
    },
    filter() {
      this.eventsSearchData.page = 1;
      this.filtrarMiembros();
    },
    async filtrarMiembros() {
      this.loadingData = true;
      try {
        this.loading();
        this.searchBarLoader = true;

        // Resetea el branch ID para la opción nacional para que no se aplique ningún filtro
        if (this.selectedOption === "nacional") {
          this.eventsSearchData.branch = ""; // Asegúrate de que no se usa ningún branch ID
          await this.fetchDataForAll(); // Puede que necesites crear este método si no existe
        } else if (
          this.selectedOption === "local" &&
          this.selectedSecondOption
        ) {
          await this.fetchDataForBranch(this.selectedSecondOption.value);
        } else if (
          this.selectedOption === "regional" &&
          this.selectedRegionalOptions.length > 0
        ) {
          this.regionalDocsCounts = []; // Reset your regional counts
          await Promise.all(
            this.selectedRegionalOptions.map((option) =>
              this.fetchDataForBranch(option.value, option.text)
            )
          );
        }

        this.loaded();
        this.searchBarLoader = false;
      } catch (error) {
        console.error("Error loading data:", error);
        this.loaded();
        this.searchBarLoader = false;
      } finally {
        this.loadingData = false;
      }
    },
    async fetchDataForAll() {
      this.clearmembersPaginated();
      try {
        let response = await this.getBranchMembersPaginated(
          this.eventsSearchData
        );
        if (response.status === true && this.membersPaginated.members) {
          // Trata los datos como necesario
        } else {
          console.error("Error in global request:", response.message);
        }
      } catch (error) {
        console.error("Failed to fetch global data:", error);
      }
    },

    async fetchDataForBranch(branchId, branchName = null) {
      if (!branchId) {
        console.error(
          "No branch ID provided for",
          branchName || "selected branch"
        );
        return; // No realiza la llamada si branchId no está definido
      }

      this.eventsSearchData.branch = branchId;
      this.clearmembersPaginated();
      try {
        let response = await this.getBranchMembersPaginated(
          this.eventsSearchData
        );
        if (response.status === true && this.membersPaginated.members) {
          this.regionalDocsCounts.push({
            name: branchName,
            totalDocs: this.membersPaginated.totalDocs,
          });
        } else {
          console.error(
            "Error in request for",
            branchName || "branch",
            response.message
          );
        }
      } catch (error) {
        console.error(
          "Failed to fetch data for",
          branchName || "branch",
          error
        );
      }
    },

    resetFilters() {
      this.modifiedFilterFields = [];
      this.filterFields.forEach((field) => (field.value = ""));
    },
    async initializeFields() {
      console.log("DONO", this.user);
      let fields;

      fields = await this.getRegistrationFields(this.user._id);

      this.memberFields = fields.data.data;
      //copy value instead of reference
      this.filterFields = JSON.parse(JSON.stringify(this.memberFields));
      this.filterFields = this.filterFields.filter(
        (field) => field.fieldType !== "DOC"
      );
    },

    exportToExcel() {
      const wb = XLSX.utils.book_new();

      const headers = [["Sección:", this.displayFilters]];
      const dataForExport = [];

      if (this.selectedOption === "nacional") {
        dataForExport.push(["Nacional", this.membersPaginated.totalDocs]);
      } else if (this.selectedOption === "local") {
        dataForExport.push([
          this.selectedSecondOption ? this.selectedSecondOption.text : "",
          this.membersPaginated.totalDocs,
        ]);
      } else if (this.selectedOption === "regional") {
        this.regionalDocsCounts.forEach((option) => {
          dataForExport.push([option.name, option.totalDocs]);
        });
      }

      // Combina las cabeceras con los datos para el libro
      const combinedData = headers.concat(dataForExport);
      const ws = XLSX.utils.aoa_to_sheet(combinedData);

      // Agregar la hoja al libro y guardar el archivo
      XLSX.utils.book_append_sheet(wb, ws, "Datos");
      XLSX.writeFile(wb, "Datos_Exportados.xlsx");
    },
    updateDisplayData() {
      this.membersPaginated.totalDocs = ""; // Resetea el total de documentos mostrados

      if (this.selectedOption === "nacional") {
        this.displayType = "Nacional";
        this.displayNames = ["Nacional"];
      } else if (this.selectedOption === "local" && this.selectedSecondOption) {
        this.displayType = "Local";
        this.displayNames = [this.selectedSecondOption.text];
      } else if (this.selectedOption === "regional") {
        this.displayType = "Regional";
        this.displayNames = this.selectedRegionalOptions.map(
          (option) => option.text
        );
        // Limpiar los conteos mientras se ajustan las opciones
        this.regionalDocsCounts = this.selectedRegionalOptions.map(
          (option) => ({
            name: option.text,
            totalDocs: "", // Limpiar los totales hasta que se apliquen los filtros
          })
        );
      }
    },
  },
  async mounted() {
    //await this.filter();
    await this.fetchBranches();
    console.log("july", this.secondOptions);

    await this.initializeFields();
  },
};
</script>

<style>
.text-yellow {
  color: orange; /* Cambia el color del texto a amarillo */
}

.theme--light.v-icon.text-yellow {
  font-size: 18px;
  color: orange;
}
.colorAccent1 {
  background-color: #fad0bf !important;
}

.colorAccent2 {
  background-color: #ffecb3 !important;
}

.colorAccent3 {
  background-color: #ffe0b2 !important;
}

.colorAccent4 {
  background-color: #cfd8dc !important;
}

.accent--text::before {
  color: #000000 !important;
}

.noShadow {
  box-shadow: none;
}

.tittlePermit {
  margin-top: 4%;
}

.bluetext {
  color: #141d30;
}

.textwhite {
  color: #ffffff !important;
}

.displayW {
  width: 100% !important;
  margin-top: 15px;
}

.displayW2 {
  width: 100% !important;
  margin-inline: 20px;
}

.textclass {
  color: #35404c;
  font-weight: bold;
}

.box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px !important;
}

.box > * {
  flex: 0 0 33.3333%;
}

/* iPhone */
@media (max-width: 875.98px) {
  b {
    font-size: 0.8em !important;
    white-space: normal;
  }

  .v-list-item__title {
    font-size: 0.8rem !important;
    white-space: normal;
  }

  .v-chip {
    font-size: 0.6rem !important;
    white-space: normal;
  }
}

.busqueda {
  padding-left: 20px;
  border: 0;
  border-color: transparent;
}

.search-prepend {
  height: 40px !important;
  border: 1px solid transparent !important;
}

.filters-card {
  max-height: 70vh;
  overflow-y: auto;
}
.higher-z-index {
  z-index: 1000; /* Adjust this value as needed */
}

.expansion-panel-width {
  max-width: 35vw;
  min-width: 35vw;
}

.total-text {
  color: #141d30;
}
</style>
