<template>
  <v-row justify="center" class="my-0 py-4">
    <v-col cols="12" md="10" lg="9" xl="8" class="white rounded-xl">
      <v-row class="ma-1">
        <v-row class="ma-0 d-flex mx-auto">
          <v-col>
            <p class="wc-h3 text-primary-variant">Inscripciones</p>
            <br />

            <FeesFilters
              :selectedRegion.sync="selectedRegion"
              :selectedBranch.sync="selectedBranch"
              :selectedBranches.sync="selectedBranches"
              :startDate.sync="startDate"
              :endDate.sync="endDate"
              :branchesOptions="branchesOptions"
              @getGraphData="fetchData()"
            ></FeesFilters>

            <FeesMap
              :selectedRegion="selectedRegion"
              :selectedBranch="selectedBranch"
              :selectedBranches="selectedBranches"
            ></FeesMap>

            <template v-if="!loadingData">
              <InscriptionsGraphs
                v-if="!loadingData"
                :graphData="graphData"
              ></InscriptionsGraphs>
              <v-expansion-panels class="mt-8">
                <v-expansion-panel
                  v-for="(result, index) in regionalResults"
                  :key="index"
                >
                  <v-expansion-panel-header class="wc-h5">
                    {{ getBranchNameById(selectedBranches[index]) }}
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <InscriptionsGraphs
                      :graphData="result"
                    ></InscriptionsGraphs>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>

            <v-row v-else justify="center" class="my-0 py-4">
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-progress-circular
                  :model-value="loadingProgress"
                  :size="240"
                  :width="40"
                  color="primary"
                  indeterminate
                >
                  <span class="primary--text font-weight-black"
                    >{{ loadingProgress }} %</span
                  >
                </v-progress-circular>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import FeesFilters from "../membership/FeesFilters.vue";
import FeesMap from "../fees/FeesMap.vue";
import InscriptionsGraphs from "./InscriptionsGraphs.vue";
export default {
  name: "Fees",
  components: {
    FeesFilters,
    FeesMap,
    InscriptionsGraphs,
  },
  data() {
    return {
      regionalResults: [],
      branchesOptions: [],
      selectedRegion: "Nacional",
      selectedBranch: "",
      selectedBranches: [],
      startDate: "",
      endDate: "",
      graphData: {
        membersFee: 0,
        submembersFee: 0,
        membersMenFee: 0,
        membersWomenFee: 0,
        submembersMenFee: 0,
        submembersWomenFee: 0,
        members: 0,
        submembers: 0,
        membersMen: 0,
        membersWomen: 0,
        submembersMen: 0,
        submembersWomen: 0,
      },
      loadingData: false,
      loadingProgress: 0,
      processIndex: 0,
    };
  },
  methods: {
    ...mapActions("branches", ["getBranches", "fetchChapterByName"]),
    getBranchNameById(id) {
      return this.branchesOptions.find((branch) => branch.value === id).text;
    },
    setDefaultDates() {
      const today = new Date();
      const firstDay = new Date(today.getFullYear(), 0, 1);

      this.startDate = this.formatDate(firstDay);
      this.endDate = this.formatDate(today);
    },
    formatDate(date) {
      return date.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    async fetchData() {
      this.loadingData = true;
      this.processIndex++;

      const processIndex = this.processIndex;
      try {
        let page = 1;
        let hasNextPage = true;

        this.graphData = {
          membersFee: 0,
          submembersFee: 0,
          membersMenFee: 0,
          membersWomenFee: 0,
          submembersMenFee: 0,
          submembersWomenFee: 0,
          members: 0,
          submembers: 0,
          membersMen: 0,
          membersWomen: 0,
          submembersMen: 0,
          submembersWomen: 0,
        };

        if (this.selectedBranches.length > 1) {
          this.regionalResults = [];

          for (let i = 0; i < this.selectedBranches.length; i++) {
            if (this.regionalResults[i]) {
              this.regionalResults[i] = {
                membersFee: 0,
                submembersFee: 0,
                membersMenFee: 0,
                membersWomenFee: 0,
                submembersMenFee: 0,
                submembersWomenFee: 0,
                members: 0,
                submembers: 0,
                membersMen: 0,
                membersWomen: 0,
                submembersMen: 0,
                submembersWomen: 0,
              };
            } else {
              this.regionalResults.push({
                membersFee: 0,
                submembersFee: 0,
                membersMenFee: 0,
                membersWomenFee: 0,
                submembersMenFee: 0,
                submembersWomenFee: 0,
                members: 0,
                submembers: 0,
                membersMen: 0,
                membersWomen: 0,
                submembersMen: 0,
                submembersWomen: 0,
              });
            }

            hasNextPage = true;
            page = 1;

            while (hasNextPage && processIndex == this.processIndex) {
              const response = await this.axios({
                method: "POST",
                url: "/affiliation-inscription/graphs",
                data: {
                  startDate: this.startDate,
                  endDate: this.endDate,
                  region: "Local",
                  branch: this.selectedBranches[i],
                  branches: [],
                  page: page,
                },
              });

              hasNextPage = response.data.hasNextPage;
              page++;

              for (const key in this.graphData) {
                if (processIndex != this.processIndex) {
                  break;
                }
                if (key != "hasNextPage" && key != "totalPages") {
                  this.graphData[key] += response.data[key];
                  this.regionalResults[i][key] += response.data[key];
                }
              }

              this.loadingProgress = parseInt(
                ((page / response.data.totalPages) * 100) /
                  this.selectedBranches.length +
                  (i * 100) / this.selectedBranches.length
              );
            }
          }
        } else {
          while (hasNextPage && processIndex == this.processIndex) {
            const response = await this.axios({
              method: "POST",
              url: "/affiliation-inscription/graphs",
              data: {
                startDate: this.startDate,
                endDate: this.endDate,
                region: this.selectedRegion,
                branch: this.selectedBranch,
                branches: this.selectedBranches,
                page: page,
              },
            });

            if (processIndex != this.processIndex) {
              break;
            }

            for (const key in this.graphData) {
              if (key != "hasNextPage" && key != "totalPages") {
                this.graphData[key] += response.data[key];
              }
            }

            hasNextPage = response.data.hasNextPage;
            this.loadingProgress = parseInt(
              (page / response.data.totalPages) * 100
            );
            page++;
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        if (processIndex == this.processIndex) {
          this.loadingData = false;
          this.loadingProgress = 0;
        }
      }
    },

    async fetchBranches() {
      try {
        const response = await this.getBranches();

        if (response.status === 200 && response.branches) {
          this.branchesOptions = response.branches.map((branch) => ({
            text: branch.company,
            value: branch._id,
          }));
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    },
  },
  created() {
    this.setDefaultDates();
    this.fetchBranches();
    this.fetchData();
  },
};
</script>

<style>
</style>