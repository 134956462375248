<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-card class="rounded-xl" elevation="0" color="#073165" rounded>
        <v-row class="ma-0 py-4 text-center h-100" justify="center">
          <span class="text-center">
            <span class="wc-overline texto-gris"> Miembros Activos </span>

            <br />
            <span class="wc-subtitle-1 text-center texto-azul-claro">
              {{ formatFees(this.graphData.membersFee + this.graphData.submembersFee) }}
            </span>
          </span>
        </v-row>
      </v-card>
      <br />

      <PieChart :chartData="chart1Data" />

      <v-card class="rounded-xl mt-8" elevation="0" color="#073165" rounded>
        <v-row class="ma-0 py-4 text-center h-100" justify="center">
          <span class="text-center">
            <span class="wc-overline texto-gris"> Renovaciones </span>

            <br />
            <span class="wc-subtitle-1 text-center texto-azul-claro">
              {{
                formatFees(this.graphData.renovationsMembersFee +
                this.graphData.renovationSubmembersFee)
              }}
            </span>
          </span>
        </v-row>
      </v-card>
      <br />

      <PieChart :chartData="chart4Data" />
    </v-col>

    <v-col cols="12" md="4">
      <v-card class="rounded-xl" elevation="0" color="#9c8f47" rounded>
        <v-row class="ma-0 py-4 text-center h-100" justify="center">
          <span class="text-center">
            <span class="wc-overline texto-gris"> Asociados Activos </span>

            <br />
            <span class="wc-subtitle-1 text-center texto-azul-claro">
              {{ formatFees(this.graphData.membersFee) }}
            </span>
          </span>
        </v-row>
      </v-card>
      <br />

      <PieChart :chartData="chart2Data" />

      <v-card class="rounded-xl mt-8" elevation="0" color="#073165" rounded>
        <v-row class="ma-0 py-4 text-center h-100" justify="center">
          <span class="text-center">
            <span class="wc-overline texto-gris"> Altas </span>

            <br />
            <span class="wc-subtitle-1 text-center texto-azul-claro">
              {{
                formatFees(this.graphData.newMembersMembersFee +
                this.graphData.newMembersSubmembersFee)
              }}
            </span>
          </span>
        </v-row>
      </v-card>

      <br />
      <!--Pie Chart 5-->
      <PieChart :chartData="chart5Data" />
    </v-col>

    <!--  -->
    <v-col cols="12" md="4">
      <v-card class="rounded-xl" elevation="0" color="#3b6498" rounded>
        <v-row class="ma-0 py-4 text-center h-100" justify="center">
          <span class="text-center">
            <span class="wc-overline texto-gris"> Afiliados Activos </span>
            <br />
            <span class="wc-subtitle-1 text-center texto-azul-claro">
              {{ formatFees(this.graphData.submembersFee) }}
            </span>
          </span>
        </v-row>
      </v-card>
      <br />
      <!--Pie Chart 3-->
      <PieChart :chartData="chart3Data" />

      <v-card class="rounded-xl mt-8" elevation="0" color="#073165" rounded>
        <v-row class="ma-0 py-4 text-center h-100" justify="center">
          <span class="text-center">
            <span class="wc-overline texto-gris">
              Miembros sin renovacion
            </span>

            <br />
            <span class="wc-subtitle-1 text-center texto-azul-claro">
              {{
                formatFees(this.graphData.notRenewMembersFee +
                this.graphData.notRenewSubmembersFee)
              }}
            </span>
          </span>
        </v-row>
      </v-card>
      <br />
      <!--Pie Chart 6-->

      <PieChart :chartData="chart6Data" />
    </v-col>
  </v-row>
</template>

<script>
import PieChart from "./PieChart.vue";
export default {
  name: "FeesGraphs",
  components: {
    PieChart,
  },
  props: {
    graphData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatFees(value) {
      let formatedNumber = new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "MXN",
      }).format(value);

      formatedNumber = formatedNumber.split(",")[0];

      return '$' + formatedNumber;
    },
  },
  computed: {
    chart1Data() {
      return {
        labels: ["Hombres", "Mujeres"],
        datasets: [
          {
            label: "Distribución",
            backgroundColor: ["rgba(40,67,135,0.9)", "rgba(227, 61, 148, 0.9"],
            data: [
              this.graphData.membersMenFee + this.graphData.submembersMenFee,
              this.graphData.membersWomenFee +
                this.graphData.submembersWomenFee,
            ],
          },
        ],
      };
    },
    chart2Data() {
      return {
        labels: ["Hombres", "Mujeres"],
        datasets: [
          {
            label: "Distribución",
            backgroundColor: ["rgba(40,67,135,0.9)", "rgba(227, 61, 148, 0.9"],
            data: [
              this.graphData.membersMenFee,
              this.graphData.membersWomenFee,
            ],
          },
        ],
      };
    },
    chart3Data() {
      return {
        labels: ["Hombres", "Mujeres"],
        datasets: [
          {
            label: "Distribución",
            backgroundColor: ["rgba(40,67,135,0.9)", "rgba(227, 61, 148, 0.9"],
            data: [
              this.graphData.submembersMenFee,
              this.graphData.submembersWomenFee,
            ],
          },
        ],
      };
    },
    chart4Data() {
      return {
        labels: ["Asociados", "Afiliados"],
        datasets: [
          {
            label: "Distribución",
            backgroundColor: ["rgba(37, 40, 80, 1)", "rgba(37, 40, 80, 0.5)"],
            data: [
              this.graphData.renovationsMembersFee,
              this.graphData.renovationSubmembersFee,
            ],
          },
        ],
      };
    },
    chart5Data() {
      return {
        labels: ["Asociados", "Afiliados"],
        datasets: [
          {
            label: "Distribución",
            backgroundColor: ["rgba(37, 40, 80, 1)", "rgba(37, 40, 80, 0.5)"],
            data: [
              this.graphData.newMembersMembersFee,
              this.graphData.newMembersSubmembersFee,
            ],
          },
        ],
      };
    },
    chart6Data() {
      return {
        labels: ["Asociados", "Afiliados"],
        datasets: [
          {
            label: "Distribución",
            backgroundColor: ["rgba(37, 40, 80, 1)", "rgba(37, 40, 80, 0.5)"],
            data: [
              this.graphData.notRenewMembersFee,
              this.graphData.notRenewSubmembersFee,
            ],
          },
        ],
      };
    },
  },
  
};
</script>

<style>
</style>